import { useTranslations } from 'next-intl'
import type { FC } from 'react'
import styled from 'styled-components'

import type { FontSize } from '@fortum/elemental-ui'
import { Box, ContentText, fontSizes, fontWeights } from '@fortum/elemental-ui'

import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { calculateFinnishContractPrice } from '@/open-web/services/calculators/calculateFinnishContractPrice'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFullLocale } from '@/shared/locale'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { defineTheme } from '../utils'

export type TotalPriceProps = {
  contractTemplate: EnrichedContractTemplate
  totalMonthlyFees: CostDetails
  totalEnergyPrice: CostDetails
  monthlyFeeDiscount: CostDetails
  energyFeeDiscount: CostDetails
  theme?: 'light' | 'dark'
  priceSize?: FontSize
  showPricingLabel?: boolean
}

export const TotalPrice: FC<TotalPriceProps> = ({
  contractTemplate,
  totalMonthlyFees,
  totalEnergyPrice,
  monthlyFeeDiscount,
  energyFeeDiscount,
  theme = 'light',
  showPricingLabel = false,
  priceSize = 'xl',
}) => {
  const t = useTranslations('purchaseFlow')
  const locale = useFullLocale()
  const { colors } = useTheme()
  const colorConfiguration = defineTheme(colors, theme)
  const { formatPriceWithUnit } = usePriceToString()

  if (!totalMonthlyFees || !totalEnergyPrice) {
    return null
  }

  const isHybrid = contractTemplate.priceType === 'HYBRID'

  const {
    energyPrice,
    monthlyFeePrice,
    energyPriceWithoutDiscounts,
    monthlyFeeWithoutDiscounts,
    isDiscounted,
  } = calculateFinnishContractPrice({
    contractTemplate,
    totalMonthlyFees,
    totalEnergyPrice,
    monthlyFeeDiscount,
    energyFeeDiscount,
    locale,
  })

  const energyUnit = totalEnergyPrice.priceUnit
  const monthlyFeeUnit = totalMonthlyFees.priceUnit

  return (
    <Box display="flex" flexDirection="column">
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        display="flex"
        justifyContent="end"
        flexWrap="wrap"
      >
        <Box tag="span" display="block">
          {formatPriceWithUnit(monthlyFeePrice, monthlyFeeUnit)}
        </Box>
        <Box tag="span" display="block">
          {' + '}
          {formatPriceWithUnit(energyPrice, energyUnit)}
        </Box>
      </ContentText>

      {isDiscounted && (
        <CrossedOutText
          color={colorConfiguration.crossedPrice}
          fontSize={fontSizes.s}
          display="flex"
          justifyContent="end"
          flexWrap="wrap"
        >
          <Box tag="span" display="block">
            {formatPriceWithUnit(monthlyFeeWithoutDiscounts, monthlyFeeUnit)}
          </Box>

          <Box tag="span" display="block">
            {' + '}
            {formatPriceWithUnit(energyPriceWithoutDiscounts, energyUnit)}
          </Box>
        </CrossedOutText>
      )}

      {showPricingLabel && (
        <ContentText flexBasis="100%" fontSize={fontSizes.xs} alignSelf="flex-end">
          {isHybrid ? `+/- ${t('consumptionImpact')}` : `+ ${t('hourlyPrice')}`}
        </ContentText>
      )}
    </Box>
  )
}

const CrossedOutText = styled(ContentText)`
  text-decoration: line-through;
`
